export const levels = [
    "Primary 1",
    "Primary 2",
    "Primary 3",
    "Primary 4",
    "Primary 5",
    "Primary 6",
    "Secondary 1",
    "Secondary 2",
    "Secondary 3",
    "Secondary 4",
    "Secondary 5"
]

export const subjectNames = [
    "Chinese",
    "Combined Science",
    "English",
    "Malay",
    "Mathematics",
    "Science",
    "Tamil"
]