// ----------------------------------------------------------------------

const account = {
  displayName: 'Jonathan Tan',
  email: 'demo@minimals.cc',
  photoURL: '/assets/images/avatars/avatar_default.jpg',
  role: 'Volunteer Manager'
};

export default account;
