// ----------------------------------------------------------------------

const superUserAccount = {
  displayName: 'SU Jonathan Tan',
  email: 'su@minimals.cc',
  photoURL: '/assets/images/avatars/avatar_14.jpg',
  role: 'Super User'
};

export default superUserAccount;
